const showMoreData = {
  layoutVariants: {
    oldSchoolTiles: `<a
  data-tile="1"
  href="{{{link text:none}}}"
  class="project-overview-grid__item project-card | {{{lc_beleuchtungsart}}}  {{{lc_land}}} {{{lc_gattungsname1}}}  {{{lc_gattungsname2}}}  {{{lc_gattungsname3}}} {{{lc_gattungsname4}}} {{{lc_gattungsname5}}} {{{lc_gattungsname6}}}"
  data-creation-date="{{{text U_Erstellungsdatum:1}}}"
>
  <img
    class="a-r-1-095"
    loading="lazy"
    src="/cdn/images/representative/{{regionskuerzel}}-{{pkm_objekt}}-260x247.jpg.webp"
    width="260"
    height="247"
    alt="{{{text objektname_nolink:1;}}}"
  />
  <div class="project-card__content">
    <h3>{{{text objektname_nolink:1;}}}</h3>
    <p>{{{text nummer:gattungsname;}}} - {{{text nummer:30;thema:objektdaten;}}}</p>
    <ul class="project-overview-grid__content">
      <li>Architekt: {{{text nummer:10;thema:objektdaten;no_hrefbuild:1}}}</li>
      <li>Lichtplaner: {{{text nummer:40;thema:objektdaten;no_hrefbuild:1}}}</li>
      <li>Fotograf: {{{text nummer:20;thema:objektdaten;no_hrefbuild:1}}}</li>
    </ul>
  </div>
</a>    `,
  },
};

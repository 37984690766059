let observer = null;

/**
 * Parallax Scroll Animation
 * 
 * This module provides a parallax scroll effect for elements with the data-js-parallax attribute.
 * The effect is applied based on the element's speed attribute, which determines the scroll speed.
 * @dependencies: toolkit.js, media.js
 * @param {Object} parallaxScrollAnimation - The parallax scroll animation module.
 * @param {Function} parallaxScrollAnimation.init - Initializes the parallax scroll animation.
 * @param {Function} updateParallax - Updates the parallax effect on scroll.
 * @param {Function} handleIntersect - Handles the intersection observer callback.
 * @param {Function} setupObserver - Initializes or disconnects the observer based on the breakpoint.
 * @param {Function} handleResize - Handles resize events with debounce for performance.
 * @param {Function} isSmallScreen - Checks if the screen is small
 * @returns {Object} - The parallax scroll animation module.
 * 
 */
const parallaxScrollAnimation = {
  init() {
    const parallaxElements = document.querySelectorAll("[data-js-parallax]");
    const elementsInView = new Set(); // Track elements currently in view

    let viewportHeight = window.innerHeight;

    // Initial setup for breakpoint and observer
    media.breakpoint.refreshValue();
    this.setupObserver(parallaxElements, elementsInView);

    // Scroll and resize listeners
    window.addEventListener("scroll", () => this.updateParallax(elementsInView, viewportHeight));
    window.addEventListener("resize", () => this.handleResize(parallaxElements, elementsInView));
  },

  // Function to update parallax effect on scroll
  updateParallax(elementsInView, viewportHeight) {
    if (this.isSmallScreen()) {
      return;
    }
    const scrollTop = window.scrollY;
    elementsInView.forEach((element) => {
      const rect = element.getBoundingClientRect();
      const offsetTop = rect.top + scrollTop;
      const speed = parseFloat(element.dataset.speed) || 0.075;
  
      const yOffset = (scrollTop - offsetTop + viewportHeight / 2) * speed;
      element.style.transform = `translateY(${-yOffset}px)`;
    });
  },
  
  // Intersection Observer callback to track elements entering/leaving view
  handleIntersect(entries, elementsInView) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        elementsInView.add(entry.target);
      } else {
        elementsInView.delete(entry.target);
      }
    });
  },
  
  // Helper function to disconnect the observer and reset transformations for small screens
  disconnectObserver(parallaxElements, elementsInView) {
    if (observer) {
      observer.disconnect(); // Actually disconnect the observer
      observer = null; // Clear the reference to fully stop observing
    }
    
    elementsInView.clear(); // Clear all elements from the set
    parallaxElements.forEach((element) => {
      element.style.transform = "translateY(0)"; // Reset position
    });
  },
  
  // Helper function to initialize the observer for larger screens
  connectObserver(parallaxElements, elementsInView) {
    observer = new IntersectionObserver((entries) => this.handleIntersect(entries, elementsInView), {
      root: null,
      rootMargin: "0px",
      threshold: 0
    });
  
    parallaxElements.forEach((element) => {
      observer.observe(element);
    });
  },
  
  // Unified observer setup logic based on screen size
  setupObserver(parallaxElements, elementsInView) {
    if (this.isSmallScreen()) {
      this.disconnectObserver(parallaxElements, elementsInView);
    } else {
      this.connectObserver(parallaxElements, elementsInView);
    }
  },
  
  // Function to handle resize events with inline debounce
  handleResize(parallaxElements, elementsInView) {
    media.breakpoint.refreshValue();
    
    // Inline debounce function
    const debouncedFunction = toolkit.debounce(() => {
      const viewportHeight = window.innerHeight;
      if (this.isSmallScreen()) {
        this.disconnectObserver(parallaxElements, elementsInView);
      } else {
        this.connectObserver(parallaxElements, elementsInView);
        this.updateParallax(elementsInView, viewportHeight);
      }
    }, 100);
  
    // Call the debounced function immediately after defining it
    debouncedFunction();
  },
  
  // Utility function to check if the screen is small (XS or SM)
  isSmallScreen() {
    const breakpoint = media.breakpoint.refreshValue();
    return breakpoint === media.breakpoint.SM || breakpoint === media.breakpoint.XS;
  }
};
